import React, {useState, useRef} from 'react'
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from 'react-table'
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid'
import { Button, PageButton } from './shared/Button'
import { classNames } from './shared/Utils'
import { SortIcon, SortUpIcon, SortDownIcon } from './shared/Icons'
import { images } from '../src/constants/icons'
import { uploads } from './constants/images'
import axiosInstance from './services/axiosconfig/AxiosAuth'
import axios from 'axios'

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-black">Search: </span>
      <input
        type="text"
        className="w-full pl-12 pr-3 py-2 text-gray-500 bg-white outline-none border  shadow-sm rounded-lg"
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </label>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">{render("Header")}: </span>
      <select
        className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  )
}

export function ActionImage({ row }) {
  const [showModal, setShowModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  
 const displayId = row.original || {};
 const upload  = row.original || {}
 console.log('displayId', displayId)


  const handleImageClick = () => {
    setShowModal(true);
  };

  const handleProfileClick = () => {
    setShowProfileModal(true);
  }

  return (
    <>
      <div>
        <img src={images.horizontalIcon} onClick={handleImageClick} />
      </div>
      {showModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className="fixed inset-0 w-full h-full bg-black opacity-40"
            onClick={() => setShowModal(false)}
          ></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-[10%] max-w-sm p-2 ml-[75rem] bg-white rounded-md shadow-lg">
              <div className="text-center">
                <h4 onClick={handleProfileClick} className="text-[14px] font-medium text-gray-800 py-2">Deactivate
                
                  {/* <a href={`/all-users/user-profile/${displayId}`}> </a> */}
                </h4>
                <h4 className="text-[14px] font-medium text-gray-800">
                  Show modal
                </h4>
              </div>
            </div>
          </div>
        </div>
      )}
        {showProfileModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className="fixed inset-0 w-full h-full bg-black opacity-40"
            onClick={() => setShowProfileModal(false)}
          ></div>
         <div>
      <div className="justify-center  overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none">
        <div className="relative w-auto my-[8rem] mx-auto max-w-[60rem]">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <main className="h-[900px] flex flex-col  sm:px-4">
              <div className="absolute top-8 right-8 bg-white w-10 h-10 px-4 py-2 rounded-2xl ">
                <button onClick={() => setShowProfileModal(false)}>x</button>
              </div>
              <div className="flex">
                  <div className="flex-1">
                  <div className="flex flex-col h-screen">
                  {/* First Row */}
                  <div className="flex-1 p-4">
                    <div className='w-[200px] h-[150px]'>
                      {upload.logo_url ? (
                        <img src={upload.logo_url} alt="Passport" />
                      ) : (
                        <img src={uploads.avatar} alt="Placeholder" className='w-[150px] h-[150px]' />
                      )}
                    </div>
                  </div>

               
                 

                  {/* Second Row */}
                  <div className="flex-1 py-2 ">
                    <div className="flex">
                        <div className="flex-1 ">
                          <h1 className="text-sm font-bold">Name</h1>
                          <p>{displayId.first_name} {displayId.last_name} {displayId.user_id}</p>
                          
                        </div>
                        <div className="flex-1 ">
                          <h1 className="text-sm font-bold">Phone Number</h1>
                          <p>{displayId.mobile}</p>
                         
                        </div>
                        <div className="flex-1 ">
                          <h1 className="text-sm font-bold">Gender</h1>
                         {displayId.gender}
                       
                        </div>
                      </div>
                  </div>
                    <div className="flex-1 py-2">
                    <div className="flex">
                        <div className="flex-1 ">
                          <h1 className="text-sm font-bold">Address</h1>
                          <p>{displayId.address}</p>
                          
                        </div>
                        <div className="flex-1 ">
                          <h1 className="text-sm font-bold">City</h1>
                          <p>{displayId.city}</p>
                         
                        </div>
                        <div className="flex-1 ">
                          <h1 className="text-sm font-bold">State</h1>
                          <p>{displayId.state}</p>
                       
                        </div>
                      </div>
                  </div>
                        <div className="flex-1 py-2 ">
                    <div className="flex">
                        <div className="flex-1 ">
                          <h1 className="text-sm font-bold">Country</h1>
                          <p>{displayId.country}</p>
                          
                        </div>
                        <div className="flex-1">
                          <h1 className="text-sm font-bold">Organisation</h1>
                          <p>{displayId.organisation}</p>
                         
                        </div>
                        <div className="flex-1 ">
                          <h1 className="text-sm font-bold">Services</h1>
                           <p>{displayId.service_type}</p> 
                       
                        </div>
                      </div>
                  </div>
                  <div className="flex-1 py-6">
                    <label className="block mb-2 text-sm font-bold" htmlFor="textInput">
                    Comment
                    </label>
                    <input
                      type="text"
                      id="textInput"
                      name="textInput"
                      className="w-[80%] h-[6rem] bg-[#DADADA] border border-[#DADADA] rounded-md p-2 focus:outline-none focus:ring focus:border-purple-400"
                      placeholder="Comment Here"
                    />
                  </div>
                  <div className="flex-1">
                  <button className="inline-flex items-center px-6 py-2 border border-gray-300 rounded-md bg-[#DB3F40] text-white hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300">
                  Disapproved
                  </button>
                  <button className="ml-2 inline-flex items-center px-12 py-2 border border-gray-300 rounded-md bg-[#47A7FD] text-white hover:bg-green-600 focus:outline-none focus:ring focus:border-green-300">
                    Approved
                  </button>
                </div>
                </div>
                    </div>
                  <div className="flex-1 py-14 ">
                      <h1 className="text-sm font-bold py-2">UPLOADS</h1>
                      <div className="flex flex-col h-[60vh] gap-6">
                        {/* First Row */}
                        <div className="flex-1 border rounded-2xl w-[450px] h-[6rem] py-4 px-6 ">
                          <h1 className="text-lg font-bold">Educational Certificate</h1>
                           <img src={uploads.qualification}/>
                        </div>

                       
                      </div>
                    </div>
                </div>
            </main>
          </div>
        </div>
      </div>
    </div>
        </div>
      )}
    </>
  );
}


  export function OrgActionImage({ row }) {
  const [showModal, setShowModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
const [errorModal, setErrorModal] = useState(false);
  const [formData, setFormData] = useState({
  comment: '',
  code: '',
  isEmergencyAgent: false,
  isVerified: true
  })

  const formRef = useRef(null);

  
 const displayId = row.original|| {};
 const upload = row.original|| {};
 const user_id = displayId.user_id

 console.log('user info', upload.cac_cert)
 const cac_cert = upload.logo;

 function openImageInNewTab(cac_cert) {
  window.open(cac_cert, '_blank');
}


  const handleChange = (e) => {
    e.preventDefault();

    if (e.target.type === 'checkbox') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [e.target.name]: !prevFormData[e.target.name],
      }));
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };


    const handleSubmit = async (e) => {
     e.preventDefault();
     setLoading(true)
      
    
      try {
        const response = await axiosInstance.post(
          `http://stagging.mymedicalbank.com/api/v1/admin/auth/verify-user/${user_id}`,
          formData,  // Send the form data directly in the request body
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
    
        if (response.status === 200) {
          setSuccessModal(true);
          console.log(response.data);  
          window.location.reload();
        }
      } catch (error) {
        setErrorModal(true);
        console.error('Error submitting form:', error);
      } finally {
        setLoading(false);
      }
    };
    

  const handleImageClick = () => {
    setShowModal(true);
  };

  const handleProfileClick = () => {
    setShowProfileModal(true);
  }

  return (
    <>
      <div>
        <img src={images.horizontalIcon} onClick={handleImageClick} />
      </div>
      {showModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className="fixed inset-0 w-full h-full bg-black opacity-40"
            onClick={() => setShowModal(false)}
          ></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-[10%] max-w-sm p-2 ml-[67rem] bg-white rounded-md shadow-lg">
              <div className="text-center">
                <h4 onClick={handleProfileClick} className="text-[14px] cursor-pointer font-medium text-gray-800 py-2">View Details
                </h4>
                <h4 className="text-[14px] font-medium text-gray-800">
                  Deactivate
                </h4>
              </div>
            </div>
          </div>
        </div>
      )}
        {showProfileModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div                              
            className="fixed inset-0 w-full h-full bg-black opacity-40"
            onClick={() => setShowProfileModal(false)}
          ></div>
         <div>
      <div className="justify-center overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none">
        <div className="relative w-auto my-[8rem] mx-auto max-w-[60rem]">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white  outline-none focus:outline-none">
            <main className="h-[800px] flex flex-col  sm:px-4">
              <div className="absolute top-8 right-8 bg-white w-10 h-10 px-4 py-2 rounded-2xl ">
                <button onClick={() => setShowProfileModal(false)}>x</button>
              </div>
              <div className="flex">
                  <div className="flex-1">
                  <div className="flex flex-col h-screen">
                  {/* First Row */}
                  <div className="flex-1 p-4">
                    <div className='w-[200px] h-[150px]'>
                      {upload.logo_url ? (
                        <img src={upload.logo_url} alt="Passport" />
                      ) : (
                        <img src={uploads.avatar} alt="Placeholder" className='w-[150px] h-[150px]' />
                      )}
                    </div>
                  </div>

               
                 

                  {/* Second Row */}
                  <div className="flex-1 py-2  ">
                    <div className="flex">
                        <div className="flex-1 px-4 ">
                          <h1 className="text-sm font-bold">Name</h1>
                          <p className='break-word'>{displayId.name}</p>
                          
                        </div>
                        <div className="flex-1 px-4 ">
                          <h1 className="text-sm font-bold">Phone Number</h1>
                          <p>{displayId.mobile}</p>
                        </div>
                        <div className="flex-1 px-4 ">
                          <h1 className="text-sm font-bold">Email</h1>
                         {displayId.email}
                        </div>
                      </div>
                  </div>
                    <div className="flex-1 py-2">
                    <div className="flex">
                        <div className="flex-1 px-4">
                          <h1 className="text-sm font-bold">Address</h1>
                          <p>{displayId.address}</p>
                          
                        </div>
                        <div className="flex-1 px-4">
                          <h1 className="text-sm font-bold">City</h1>
                          <p>{displayId.location_city}</p>
                         
                        </div>
                        <div className="flex-1 px-4 ">
                          <h1 className="text-sm font-bold">State</h1>
                          <p>{displayId.location_state}</p>
                       
                        </div>
                      </div>
                  </div>
                     <div className="flex-1 py-2 ">
                    <div className="flex">
                        <div className="flex-1 px-4">
                          <h1 className="text-sm font-bold">Country</h1>
                          <p>{displayId.country}</p>
                          
                        </div>
                        <div className="flex-1">
                          <h1 className="text-sm font-bold">Organisation Type</h1>
                          <p>{displayId.type}</p>
                         
                        </div>
                        <div className="flex-1 px-4 ">
                          <h1 className="text-sm font-bold">Services</h1>
                          <span className="text-sm">
      {displayId.service_type && displayId.service_type.length > 0
        ? displayId.service_type.join(', ')
        : 'N/A'}
    </span>
                       
                        </div>
                      </div>
                 
                  </div>
                  <div className='flex-1 py-2'>
                  <div className='flex'>
                      <div className="flex-1 px-4 ">
                          <h1 className="text-sm font-bold">websit</h1>
                          <p>{displayId.website}</p>
                          
                        </div>
                      </div>
                  </div>
                  <form ref={formRef} onSubmit={handleSubmit}>
                  <div className='flex-1 py-2'>
                  <div class="flex items-center">
                  <div class="flex items-center">
                  <input
                      id="link-checkbox"
                      type="checkbox"
                         onChange={(e) => {
                  e.stopPropagation();
                  handleChange(e);
                }}
                      checked={formData.isEmergencyAgent}  
                      name="isEmergencyAgent"
                      className="w-4 h-4 text-blue-600 bg-gray-100
                      border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 
                      focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  <label htmlFor="link-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Allow access to Emergency Service</label>
                  </div>
                 </div>
                  </div>
                  <div className="flex-1 py-6">
                    <label className="block mb-2 text-sm font-bold" htmlFor="textInput">
                    Comment
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      value={formData.comment}
                      name= "comment"
                      id="textInput"
                      className="w-[80%] h-[6rem] bg-[#DADADA] border border-[#DADADA] rounded-md p-2 focus:outline-none focus:ring focus:border-purple-400"
                      placeholder="Comment Here"
                    />
                  </div>
                  <div className="flex-1">
                  <button type = "button" onClick={handleSubmit} className="inline-flex items-center px-6 py-2 border border-gray-300 rounded-md bg-[#DB3F40] text-white hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300">
                  Disapproved
                  </button>
                  <button type = "button" onClick={handleSubmit} className="ml-2 inline-flex items-center px-12 py-2 border border-gray-300
                   rounded-md bg-[#47A7FD] text-white hover:bg-[#47A7FD] focus:outline-none focus:ring focus:bg-[#47A7FD]" disabled={loading}> Approved
                                  {loading ?  
                                 <svg aria-hidden="true" class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                 <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                 </svg> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-6 pl-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                </svg>}

                        </button>
                </div>
                </form>
                </div>
                
                    </div>
                  <div className="flex-1 py-14 ">
                      <h1 className="text-sm font-bold py-2">UPLOADS</h1>
                      <div className="flex flex-col h-auto gap-6">
                        {/* First Row */}
                        <div className="flex-1 border rounded-2xl w-auto h-[6rem] py-4 px-6 ">
                          <h1 className="text-lg font-bold">Educational Certificate</h1>
                          Uploaded:  
                          <Button onClick={() => openImageInNewTab(cac_cert)}>View</Button>
                       
                        </div>

                      
                      </div>
                    </div>
                    {successModal && (
                      <div className="fixed inset-0 z-10 overflow-y-auto">
                        {/* ... Success modal content */}
                      </div>
                    )}

                    {errorModal && (
                      <div className="fixed inset-0 z-10 overflow-y-auto">
                        {/* ... Error modal content */}
                      </div>
                    )}
                </div>
            </main>
          </div>
        </div>
      </div>
    </div>
        </div>
      )}
    </>
  );
}
 



export function StatusPill({ row }) {
  const displayId = row.original || {};
  const isApproved = displayId.approved;

  return (
    <span
      className={classNames(
        "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
        isApproved ? "bg-green-100 text-green-800" : "bg-yellow-100 text-yellow-800",
      )}
    >
      {isApproved ? 'Approved' : 'Pending'}
    </span>
  );
}

export function AvatarCell({ value, column, row }) {
  return (
    <div className="flex items-center">
      <div className="flex-shrink-0 h-10 w-10">
        <img className="h-10 w-10 rounded-full" src={row.original[column.imgAccessor]} alt="" />
      </div>
      <div className="ml-4">
        <div className="text-sm font-medium text-gray-900">{value}</div>
        <div className="text-sm text-black">{row.original[column.emailAccessor]}</div>
      </div>
    </div>
  )
}

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable({
    columns,
    data,
  },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination,  // new
  )

  // Render the UI for your table
  return (
    <>
      <div className="sm:flex sm:gap-x-2">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render("Filter")}
              </div>
            ) : null
          )
        )}
      </div>
      {/* table */}
      <div className="mt-4 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                <thead className="bg-bgblue">
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          scope="col"
                          className="group px-2 py-2  text-xs  text-white font-Roboto"
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                        >
                          <div className="flex items-center ">
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? <SortDownIcon className="w-4 h-4 text-white" />
                                  : <SortUpIcon className="w-4 h-4 text-white" />
                                : (
                                  <SortIcon className="w-4 h-4 text-white" />
                                )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200"
                >
                  {page.map((row, i) => {  // new
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="px-6 py-4 whitespace-nowrap"
                              role="cell"
                            >
                              {cell.column.Cell.name === "defaultRenderer"
                                ? <div className="text-sm text-black">{cell.render('Cell')}</div>
                                : cell.render('Cell')
                              }
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Pagination */}
      <div className="py-3 flex items-center justify-between">
        <div className="flex-1 flex justify-between sm:hidden">
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>Next</Button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="flex gap-x-2 items-baseline">
            <span className="text-sm text-gray-700">
              Page <span className="font-medium">{state.pageIndex + 1}</span> of <span className="font-medium">{pageOptions.length}</span>
            </span>
            <label>
              <span className="sr-only">Items Per Page</span>
              <select
                className="mt-1 block py-2 px-2 w-full  outline-none border  shadow-sm rounded-lg"
                value={state.pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
              >
                {[5, 10, 20, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <PageButton
                className="rounded-l-md"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">First</span>
                <ChevronDoubleLeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </PageButton>
              <PageButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </PageButton>
              <PageButton
                onClick={() => nextPage()}
                disabled={!canNextPage
                }>
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </PageButton>
              <PageButton
                className="rounded-r-md"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <span className="sr-only">Last</span>
                <ChevronDoubleRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </PageButton>
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}

export default Table;