import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from '../../axiosconfig/AxiosAuth.js'

const BASE_URL = 'http://stagging.mymedicalbank.com';


//Health workers onboarding from
export const submitForm = createAsyncThunk(
  '/profileForm/submitForm',
  async (formData, { getState, rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getState().auth.accessToken}`,
        },
      };

      // Construct the URL with the token as a query parameter
      const token = getState().auth.accessToken;
      console.log('tokenz', token);
      const url = `${BASE_URL}/api/v1/cooperate/health-worker/add-details`;

      const response = await axios.post(
        url, 
        formData,
        config
      );

      return response.data; 

    } catch (error) {
      // Return backend error message
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//corporate Registration form
export const submitOrgRegForm = createAsyncThunk(
  '/profileForm/submitForm',
  async (formData, { getState, rejectWithValue }) => {
    try {
      const token = getState().auth.accessToken;
      console.log('Current Redux State:', getState());
      console.log('Current Token:', token);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      };

      // Construct the URL with the token as a query parameter
     
      console.log('token', token);
      const url = `${BASE_URL}/api/v1/cooperate/organisation/add-organisation`;

      const response = await axios.post(
        url, 
        formData,
        config
      );

      return response.data; 

    } catch (error) {
      // Return backend error message
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// Emrgency Access Validation
export const EmergencyCode = createAsyncThunk(
  '/emergency',
  async (formData, { getState, rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };


      const url = `${BASE_URL}/api/v1/user/access-code`;

      const response = await axios.post(
        url, 
        formData,
        config
      );

      return response.data; 

    } catch (error) {
      // Return backend error message
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//User Emegency info with phone number

export const UserEmergency = createAsyncThunk(
  '/emergency/user',
  async (formData, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const url = `${BASE_URL}/api/v1/user/emergency-contact`;

      const response = await axios.post(
        url, 
        formData,
        config
      );

      console.log(response.data)

      return response.data; 

    } catch (error) {
      // Return backend error message
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

