import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Admin/dashboard/Sidebar";
import DashboardHeader from "../../components/Admin/dashboard/DashboardHeader";
import AllUserCard from "../../components/Admin/users/AllUserCard";
import AllUserSearchContainer from "../../containers/allUsersContainer/AllUserSearchContainer";
import ShowingUserData from "../../containers/allUsersContainer/ShowingUserData";
import axios from "axios";

export const delQuery = (asPath) => {
  return asPath.split("?")[0];
};

const AllUsers = () => {

  const [superAdmindata, setData] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const apiUrl = "http://stagging.mymedicalbank.com/api/v1/admin/dashboard";
    axios
      .get(apiUrl)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was a problem with the Axios GET request:", error);
        setLoading(false);
      });
  }, []);

  console.log(superAdmindata?.data?.users, loading);

  const rows = superAdmindata?.data?.users.filter(
    (value) => value.email !== ""
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };






  const [isOpen, setOpen] = useState(false);
  return (
    <div className="flex justify-between bg-[#F9F9F9]  font-Montserrat">
      <Sidebar isOpen={isOpen} setOpen={setOpen} />

      <div className="w-[calc(100%-212px)] xl:w-[calc(100%-212px)] max-[650px]:w-[90%] max-[650px]:pr-[10px]  max-[650px]:pl-[10px]  pl-[33px] pt-[14px] pr-[59px]">
        <DashboardHeader isOpen={isOpen} setOpen={setOpen} />

        <main className="line-clamp-1 lg:pt-[25px]    min-h-[100vh]  xl:pt-[25px] max-[650px]:flex-col max-[650px]:w-[90%]">
          <AllUserSearchContainer />
          <div className="flex flex-wrap w-[100%] gap-4 mt-[70px]">
            {superAdmindata &&  (rowsPerPage > 0
                  ? rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows
                ).map((card, idx) => (
              <AllUserCard key={idx} {...card}/>
              
            ))}
              {emptyRows > 0 && (
              <tr style={{ height: 41 * emptyRows }}>
                <td colSpan={3} aria-hidden />
              </tr>
            )}
          </div>
          <ShowingUserData rows={rows} page={page} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage}/>
        </main>
      </div>
    </div>
  );
};

export default AllUsers;
