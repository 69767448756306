// src/store.js
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authApi } from '../redux/auth/authAction/authService';
import dashboardReducer from "../redux/reducers/dashboardReducer";
import authReducer from "../redux/auth/authSlice/authSlice"
import formReducer from "./slice/formSlice"
import emergencyReducer from './slice/emergencySlice'
import userEmergencyReducer from './slice/userEmergencySlice'
import userDashboardReducer from './slice/dashboardSlice'
 


// Redux Persist configuration
const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, authReducer);

const store = configureStore({
    reducer: {
      dashboard: dashboardReducer,
      auth: persistedReducer,
      [authApi.reducerPath]: authApi.reducer,
      profileForm: formReducer,
      emergency: emergencyReducer,
      useremergency: userEmergencyReducer,
      userDetails:userDashboardReducer
   
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authApi.middleware),
  });

  export const persistor = persistStore(store);

  export default store;
