import { sideIcons } from "../../constants/icons";
import {userSidebarImage} from "../../constants/images"

export const navigations = [
  {
    sectionTitle: "Main Menu",
    links: [
      {
        label: "Dashboard",
        icon: sideIcons.dashboardIcon,
        to: "/super-admin-dashboard",
      },
      {
        label: "Organisation",
        icon: sideIcons.briefCaseIcon,
        to: "/all-organisation",
      },
      {
        label: "Health Workers",
        icon: sideIcons.briefCaseIcon,
        to: "/all-HealthWorkers",
      },
      {
        label: "Clients",
        icon: sideIcons.usersIcon,
        to: "/",
      },
      {
        label: "Services",
        icon: sideIcons.hardDriveIcon,
        to: "/",
      },
      {
        label: "Broadcast Message",
        icon: sideIcons.sendIcon,
        to: "/broadcast",
      },
      {
        label: "Users",
        icon: sideIcons.usersIcon,
        to: "/",
      },
      {
        label: "Transactions",
        icon: sideIcons.creditCardIcon,
        to: "/",
      },

      {
        label: "Settings",
        icon: sideIcons.settingIcon,
        to: "/",
      },
      {
        label: "All users",
        icon: sideIcons.usersIcon,
        to: "/all-users",
      },
    ],
  },
];




export const profileDetail = {
  image: sideIcons.profile,
  name: "Aderopo Oluwasegun",
  title: "Super Admin",
  logo: sideIcons.dropdown,
};

//Corporate sidebar links

export const corporateLinks = [
  {
    label: "Emergency Information",
    icon: sideIcons.dashboardIcon,
    to: "/",
  },
  // {
  //   label: "Dashboard",
  //   icon: sideIcons.dashboardIcon,
  //   to: "/",
  // },
  {
    label: "Appointments",
    icon: sideIcons.briefCaseIcon,
    to: "/",
  },
  {
    label: "Services",
    icon: sideIcons.hardDriveIcon,
    to: "/",
  },
  {
    label: "Messages",
    icon: sideIcons.sendIcon,
    to: "/",
  },
  {
    label: "Transactions",
    icon: sideIcons.creditCardIcon,
    to: "/",
  },
  {
    label: "Help Center",
    icon: sideIcons.helpcenter,
    to: "/",
  },

  {
    label: "Settings",
    icon: sideIcons.settingIcon,
    to: "/",
  },
];

export const referers = {
  title: "Refer a friend",
  image: userSidebarImage.referrerImg,
};
