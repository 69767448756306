import React from 'react';


export const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      height:'2.8rem',
      border: '1px',
      borderRadius: '0.5rem',
      backgroundColor: '#F3F3F3',
    }),
    multiValue: (provided) => ({
      ...provided,
      background: '#3182CE',
      color: 'white',
      borderRadius: '0.25rem',
      padding: '0.2rem 0.5rem',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'white',
      ':hover': {
        background: 'transparent',
        color: 'white',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#3182CE' : 'white',
      color: state.isSelected ? 'white' : 'black',
    }),
  };