import { useEffect, useState } from 'react'
import { logo } from '../../../constants/images';
import Register from './Register';
import Login from './Login';

export default () => {

    const [state, setState] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);

    const handleChange = (e) => {
        // Handle form input changes here
      };

    // Replace javascript:void(0) paths with real paths
    const navigation = [
        { title: "Services", path: "javascript:void(0)" },
        { title: "About", path: "javascript:void(0)" },
        { title: "Resources", path: "javascript:void(0)" },
        { title: "Contact", path: "javascript:void(0)" }
    ]

    useEffect(() => {
        document.onclick = (e) => {
            const target = e.target;
            if (!target.closest(".menu-btn")) setState(false);
        };
    }, [])

    const Redirect = () => {
        window.location.href = '/emergency';
    }


    const Brand = () => (
        <div className="flex items-center justify-between py-5 md:block">
            <a href="javascript:void(0)">
                <img
                    src={logo.mmbLogo}
                    width={150}
                    height={60}
                    alt="mymedicalbank logo"
                />
            </a>
            <div className="md:hidden">
                <button className="menu-btn text-gray-500 hover:text-gray-800"
                    onClick={() => setState(!state)}>
                    {
                        state ? (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        )
                    }
                </button>
            </div>
        </div>
    )

    return (
        <div className='relative'>
            <div className='absolute inset-0 blur-xl h-[580px]' style={{ background: "#E5EFFB" }}></div>
            <div className='relative'>
                <header>
                    <div className={`md:hidden ${state ? "mx-2 pb-5" : "hidden"}`}>
                        <Brand />
                     </div>
                        <nav className={`pb-5 md:text-sm ${state ? "absolute top-0 inset-x-0 bg-white shadow-lg rounded-xl border mx-2 mt-2 md:shadow-none md:border-none md:mx-0 md:mt-0 md:relative md:bg-transparent" : ""}`}>
                            <div className="gap-x-14 items-center max-w-screen-xl mx-auto px-4 md:flex md:px-8">
                                <Brand />
                                <div className={`flex-1 items-center mt-8 md:mt-0 md:flex ${state ? 'block' : 'hidden'} `}>
                                    <ul className="flex-1 justify-center items-center space-y-6 md:flex md:space-x-6 md:space-y-0">
                                        {
                                            navigation.map((item, idx) => {
                                                return (
                                                    <li key={idx} className="text-gray-700 hover:text-gray-900">
                                                        <a href={item.path} className="block">
                                                            {item.title}
                                                        </a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    <div className="flex-1 items-center justify-end gap-x-6 space-y-3 md:flex md:space-y-0">
                                    <button onClick={() => Redirect()} className="flex items-center justify-center gap-x-1 py-2 px-4 text-white font-medium bg-[#DB3F40] hover:bg-gray-700 active:bg-gray-900 rounded-xl md:inline-flex">
                                        Emergency
                                        </button>
                                        <button onClick={() => setShowLoginModal(true)} className="flex items-center justify-center gap-x-1 py-2 px-4 text-white font-medium bg-[#47A7FD] hover:bg-gray-700 active:bg-gray-900 rounded-xl md:inline-flex">
                                        Login
                                        </button>
                                        <button  onClick={() => setShowModal(true)}  className="flex items-center justify-center gap-x-1 py-2 px-4 text-white font-medium bg-[#47A7FD] hover:bg-gray-700 active:bg-gray-900 rounded-xl md:inline-flex">
                                        Register
                                        </button>
                                    </div>  
                                </div>
                            </div>
                        </nav>
                </header>
            </div>
            {showModal ? (
             <>
             <Register handleChange={handleChange} setShowModal={setShowModal} />
               <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
            ) : null}
            {showLoginModal ? (
               <>
               <Login handleOnChange={handleChange} setShowLoginModal={setShowLoginModal} />
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
                ) : null}
                    </div>    
                )
            }