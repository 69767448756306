import React from 'react'

const StepSidebar = ({step1Text, step2Text}) => {
  return (
    <div className='bg-white w-[348px] h-[862px] rounded-[20px] py-8'>    
        <ol class="relative text-gray-500 border-l y-8 mx-8 border-gray-200 dark:border-gray-700 dark:text-gray-400 ">                  
            <li class="mb-10 ml-12 ">            
                <span class="absolute  text-white font-bold font-Roboto flex items-center justify-center w-[40px] h-[40px] bg-[#47A7FD] 
                rounded-xl -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                   1
                </span>
                <h3 class="font-bold text-[19.5px] leading-tight text-[#303030] ">Step 1</h3>
                <p class="text-[14.25px] text-start text-[#303030] max-w-xs font-Poppins">{step1Text}</p>
            </li>
            <li class="mb-10 ml-12">
                <span class="absolute text-[#47A7FD] font-bold font-Roboto flex items-center justify-center w-[40px] h-[40px]
                 bg-[#C9E5FF] rounded-xl -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                  2
                </span>
                <h3 class="font-bold text-[19.5px] leading-tight text-[#303030]">Step 2</h3>
                <p class="text-[14.25px] text-start text-[#303030] font-Poppins">{step2Text}</p>
            </li>
          
        </ol>
    </div>
  )
}

export default StepSidebar