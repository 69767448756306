import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Admin/dashboard/Sidebar";
import DashboardHeader from "../../components/Admin/dashboard/DashboardHeader";
import DashboardContainer from "../../containers/dashboardContainer/DashboardContainer";
import DashboardAsideContainer from "../../containers/dashboardContainer/DashboardAsideContainer";
import axios from "axios";

export const delQuery = (asPath) => {
  return asPath.split("?")[0];
};

const Dashboard = () => {
  const [superAdmindata, setData] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const apiUrl = "http://stagging.mymedicalbank.com/api/v1/admin/dashboard";
    axios
      .get(apiUrl)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was a problem with the Axios GET request:", error);
        setLoading(false);
      });
  }, []);

  console.log(superAdmindata, loading);

  const [isOpen, setOpen] = useState(false);
  return (
    <div className="flex justify-between bg-[#F9F9F9]  font-Montserrat">
      <Sidebar isOpen={isOpen} setOpen={setOpen} />

      <div className="w-[calc(100%-212px)] xl:w-[calc(100%-212px)] max-[650px]:w-[90%] max-[650px]:pr-[10px]  max-[650px]:pl-[10px]  pl-[33px] pt-[14px] pr-[59px]">
        <DashboardHeader isOpen={isOpen} setOpen={setOpen} />

        <main className="line-clamp-1 lg:pt-[25px]    min-h-[100vh]  xl:pt-[25px] flex justify-between max-[650px]:flex-col max-[650px]:w-[90%]">
          <div className="w-[calc(100%-230px)] mr-[18px] max-[650px]:w-[90%]  max-[650px]:mr-[0px]">
            <DashboardContainer superAdmindata={superAdmindata} />
          </div>
          <div className="w-[230px] max-[650px]:w-[90%]">
            <DashboardAsideContainer />
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
