import React, { useState, useEffect } from "react";
import UserProfileHeader from "../../components/Users/ProfileSetup/UserProfileHeader";
import StepSidebar from '../../components/Users/HealthWorkerProfile/StepSidebar'
import ProfileForm from '../../components/Users/HealthWorkerProfile/ProfileForm'




const HealthWorkerProfile = () => {
  return (
    <div>
    <UserProfileHeader Breadcrumb = "Registraion"/>
    <div className="bg-[#F7F8FA] h-auto">
      <div className="flex flex-wrap">
        <div className="w-2/2 px-8">
        <StepSidebar 
        step1Text="Input Your Personal Information and Services"
         step2Text="Input Your Qualifications"
         />
        </div>
        <div className="w-2/2 px-12">
        <ProfileForm/>
        </div>
      </div>
  
    </div>
    </div>
  )
}

export default HealthWorkerProfile