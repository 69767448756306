import React from 'react'
import { useNavigate } from 'react-router-dom';


const ProfileSuccess= () => {
  const navigate = useNavigate();



  const handleClickButton = (id) => {
  navigate('/health-worker-dashboard', {replace: true})
  }

  return (
    <div>
    <div className='mx-20 py-10'>
      <h5>Completed</h5>
      <p>you are done with Registration.</p>
    </div>
    <div className='flex flex-col mx-20'>
      <div className='w-[40%] mb-[10rem] bg-gray-500 px-5 py-2 bg-[#FFFBDA] '>
      <h5 className=''>Completed</h5>
      <p>we are  correctly processing your registration.</p>
      </div>
      <div className='w-full  mb-4 px-14 '>
       <button  onClick={handleClickButton} className='text-white w-[10rem] bg-bgbutton py-2 px-2'>return to dashboard</button>
      </div>
    </div>
    </div>
  )
}

export default ProfileSuccess