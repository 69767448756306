import React, { useState, useEffect } from 'react'
import { logo, healthWorkerImages } from '../../../constants/images'
import { useDispatch, useSelector } from 'react-redux'
import { useGetUserDetailsQuery } from '../../../services/redux/auth/authAction/authService';
import { setCredentials, logout } from '../../../services/redux/auth/authSlice/authSlice';
import { images } from "../../../constants/icons";
import { userLogout } from '../../../services/redux/auth/authAction/authAction';
import { useNavigate } from 'react-router-dom';



const UserProfileHeader = (props) => {


    const { userInfo } = useSelector((state) => state.auth);
    const dispatch = useDispatch()

    console.log('info', userInfo);

      // automatically authenticate user if token is found
     const { data, isFetching } = useGetUserDetailsQuery('userDetails', {
    // perform a refetch every 15mins
    //   pollingInterval: 900000,
    })

  console.log('data', data);
    useEffect(() => {
        if (data) dispatch(setCredentials(data))
        // console.log('data', data)
      }, [data, dispatch])

      const navigate = useNavigate();

      const handleLogout = () => {
        dispatch(userLogout());
        navigate("/")
      }

    



        return (
            <div className=" w-[100%] bg-[#F7F8FA] mx-auto px-4 pt-4 md:px-8">
                <div className="items-start justify-between gap-x-4 py-4  sm:flex">
                    
                    <div className="max-w-lg">
                        <img src={logo.mmbLogo}/>
                        <p className="text-black-600 mt-4 pl-[2rem] lg:pl-[12rem] divide-x">
                           {props.Breadcrumb}
                        </p>
                    </div>
                    <div className="flex items-center mr-[-50rem] w-[50px]">
  <button onClick={handleLogout}>
    <img src={images.powerIcon} alt="" />
  </button>
</div>

                    <div className="mt-6 sm:mt-0 bg-white rounded-lg gap-2.5 shadow-lg lg:w-[20%] lg:h-[51px] ">
                        
                 
                        <div className="relative">
                        <div className="flex items-center py-2 px-4">
                            <img className="w-10 h-10 rounded-full" src={healthWorkerImages.estherImage} alt=""/>
                            <div className="font-medium dark:text-white">
                                <div className='px-2 font-Montserrat text-lg font-medium leading-[16px]'>First Name</div>
                                <div className="px-2 text-sm font-Poppins text-gray-400 dark:text-gray-400">
                                    {isFetching
                                        ? `Fetching your profile...`
                                        : userInfo !== null
                                    ? userInfo?.data?.account_type
                                    : 'h'
                                    }
                                    </div> 
                            </div>
                          
                        </div>
                      

                        </div>
                    </div>
                </div>
            </div>
        )
    }


export default UserProfileHeader;