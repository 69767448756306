import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logo } from "../../constants/images";
import FormField from '../FormField';
import Error from '../global/Error';
import {EmergencyCode} from '../../services/redux/actions/formAction'
import { useDispatch, useSelector } from 'react-redux';
import Register from '../../components/Users/LandingPage/Register';








const Emergency = () =>  {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    code: "",
  });



  const { loading, error, code, } = useSelector((state) => state.emergency); 



    //handle change event
    const handleChange = (e) => {
       setFormData({
        ...formData,
       [e.target.name]: e.target.value,
       });
    };

    const navigate = useNavigate();

      const backToWebsite = () => {
        window.location.href = 'https://mymedicalbank.com';
      };
  

    
  // Redirect user to user info screen
  useEffect(() => {
    
    if (code) {
      const sessionId = code.data.sessionId;
      navigate(`/emergency/user/${sessionId}`);
    }
  }, [navigate, code]); 

  

 //handle submit button
 const dispatch = useDispatch();

  const AccessEmergency = (e) => {
    e.preventDefault();
    dispatch(EmergencyCode(formData))
  };

  

  return (
    <>
    <header className="text-gray-600 body-font h-[100vh]">
    <div className=" mx-auto flex flex-nowrap p-8 flex-col md:flex-row items-center">
      <a className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
        <span className="ml-3 text-xl w-[100%]">
          <img src={logo.mmbLogo} alt='mymedicalbank logo'/>
        </span>
      </a>
      <nav className="md:ml-auto flex flex-nowrap items-center text-base justify-center ">
      <button className="inline-flex items-center bg-[#47A7FD] text-white  me-12 mb-2  py-2.5 px-5 focus:outline-none
        border hover:-bg-[#47A7FD]  rounded-lg text-base " onClick={() => setShowModal(true)}>
        Register 
      </button>
      <button type="button" class="text-[#47A7FD] w-auto hover:text-white border border-[#47A7FD] hover:bg-[#47A7FD]
      focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg lg:text-base px-5 py-2.5 text-center 
      me-2 mb-2 dark:border-[#47A7FD]-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500
       dark:focus:ring-[#47A7FD]"   onClick={backToWebsite}>Back to Website</button>
      </nav>
    </div>
    <div className='flex flex-col'>
      <div className=''>
      <p className='text-[23px] lg:text-[33px] font-Roboto  font-bold px-8 text-black'>Emergency Health Information Access</p>
      <h5 className='max-w-[70rem] py-2 lg:py-8 justify-center font-Poppins font-medium px-8 text-black leading-7'>Welcome to our Emergency Health Information Access portal. 
        If you have an emergency access code, please enter it below to retrieve
         emergency health information for the victim. If you are an 
         organization dealing with emergencies and haven't registered yet, 
         take a moment to register now. Registration is the key to obtaining
          the emergency code required for this feature.</h5>
      </div>
      <div className='px-8 py-8'>
        <form onSubmit={AccessEmergency}>
          {error && <Error message={error}>{error}</Error>}
        <label className='text-lg  text-black font-normal font-Poppins leading-4 '>Emergency Code</label>
        <FormField
         type="text"
         name="code"
         placeholder="** Enter Your Emergency  Code"
         onChange = {handleChange}
         value={formData.code}
         className="appearance-none block w-[75%] lg:w-[25%] bg-gray-200
           text-gray-700 border border-[#47A7FD] rounded items-center 
           py-4 my-2 px-2 mb-3 leading-tight focus:outline-none focus:bg-white"
         />
         {loading ?
         <button type="submit" className="duration-250 block items-center w-[75%] lg:w-[25%] bg-[#47A7FD] text-white
         text-center  me-12 mb-2 my-5 py-3 px-5 focus:outline-none
         border hover:-bg-[#47A7FD]  rounded-lg text-base
            " disabled={loading}>  
                 <svg aria-hidden="true" className="inline w-8 h-8  text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                 <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                 </svg> 
           </button> :
            <button type="submit" className="block items-center w-auto lg:w-[25%] bg-[#47A7FD] text-white
            text-center  me-12 mb-2 my-5 py-3 px-5 focus:outline-none
            border hover:-bg-[#47A7FD]  rounded-lg text-base " disabled={loading}>
            Access Emergency Information 
            </button>
             
          }
      </form>
      </div>
      <div className='px-8 py-8 text-black'>
        <p className='font-bold text-black font-Roboto'>Note: <span className=' font-mono font-medium  text-black leading-5'>
          Access to the Emergency Health Information feature is exclusive to registered 
          emergency organisations. By registering, you contribute to a faster and more effective response during
           emergencies.</span></p>
      </div>

    </div>
  
  </header>

  {showModal ? (
                <>
                <Register handleChange={handleChange} setShowModal={setShowModal} />
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
               </>
               ) : null}

  </>
  
  
  
  )
}

export default Emergency;