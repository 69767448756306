import React from "react";
import { messaging } from "../../../../services/data/activitiesData";
import { images } from "../../../../constants/icons"

const ActivitiesHealthWorker = ({ className, seeAll }) => {
  return (
    <div className="h-[389x] w-[385px] bg-white rounded-lg p-[20px] font-Montserrat">
      <div className="flex justify-between mb-2">
        <p className="text-base font-bold">Activities</p>
        <p>
          <img src={images.verticalIcon} alt="" />
        </p>
      </div>
      <div className="flex justify-between"> 
        
        <div className="py-[20px]">
          {messaging &&
            messaging.map((item, i) => (
              <div key={i} className="flex justify-between py-1">
                <div className="w-[18%]">
                  <img src={item.image} alt="" />
                </div>
                <div className="w-[80%] mb-2">
                  <p className="text-[12px]  font-semibold">{item.name}</p>
                  <p className="text-[10px] text-[#888888]">{item.mssg}</p>
                </div>
              </div>
            ))}
        </div>
        <div className="py-[20px]">
        {messaging &&
          messaging.map((item, i) => (
            <div
              key={i}
              className="flex justify-between py-1"
            >
              <div className="w-[18%]">    
                <img src={item.image} alt="" />
              </div>
              <div className="w-[80%] mb-2">
                <p className="text-[12px]  font-semibold">{item.name}</p>
                <p className="text-[10px] text-[#888888]">{item.mssg}</p>
              </div>
            </div>
          ))}
      </div>
      </div>

      <div className="flex justify-end font-semibold text-sm ">
        <p>See all</p>
      </div>
    </div>
  );
};

export default ActivitiesHealthWorker;
