import RoutesManager from "./routesManager/RoutesManager";
import userRutes from './routesManager/UserRoutesManager'
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./authProvider";
import { Provider } from "react-redux";
import store from "./services/redux/store";

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <RoutesManager />
      </AuthProvider>
    </Provider>
  );
}

export default App;
