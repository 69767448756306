import React, { useState, useEffect } from "react";
import UserProfileHeader from "../../components/Users/ProfileSetup/UserProfileHeader";
import ProfileSuccess from "../../components/Users/HealthWorkerProfile/ProfileSuccess";


const ProfileCompleted = () => {
  return (
    <div>
    <UserProfileHeader Breadcrumb = "Registraion"/>
     <ProfileSuccess/>
    </div>
  )
}

export default ProfileCompleted