import React from "react";
import PaymentHealthWorker from "../../components/Users/HealthWorkersUsers/healthWorker/PaymentHealthWorker";
import { images } from "../../constants/icons";
import DonutChart from "../../components/global/DonutChart";

const PaymentHealthContainer = () => {
  const title = "Payment";
  return (
    <div className="bg-white rounded-lg p-[20px] w-[268px] h-[373px]">
      <div className="flex justify-between items-center ">
        <p className="text-base font-bold"> {title}</p>
        <p>
          <img src={images.verticalIcon} alt="" />
        </p>
      </div>

      <div className="flex justify-center">
        <div className="h-[141.22px] w-[121.22px] ">
        <DonutChart/>
        </div>
       
      </div>
      <PaymentHealthWorker />
    </div>
  );
};

export default PaymentHealthContainer;
