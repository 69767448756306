



    export const services = [
        { value: 'option1', label: 'Homecare' },
        { value: 'option2', label: 'Option 2' },
        { value: 'option3', label: 'Option 3' },
        { value: 'option4', label: 'Option 4' },
        { value: 'option5', label: 'Option 5' },
      ];

      export const gender = [
        { value: 'option1', label: 'Male' },
        { value: 'option2', label: 'Female ' },
      
      ];

      export const specialty = [
        { value: 'Female', label: 'Female ' },
        {value: 'Pediatrics', label: 'Pediatrics'}
      
      ];