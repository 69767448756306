import React from "react";
import { paymentData as payment } from "../../../../services/data/corporateData";

const PaymentHealthWorker = () => {
  return (
    <div className="w-[230px]">
      {payment &&
        payment.map((item, idx) => (
          <div key={idx}>
            <p className="text-[14px]">{item.title}</p>

            <div >
              {item.balanceDetails.map((data, idx) => (
                <div key={idx} className="flex justify-between  text-[12px]">
                  <div> <p className="text-textgray py-2">{data.name}</p></div>
                  <div> <p className="text-[#333333] font-medium">{data.balance}</p></div>
                 
                 
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default PaymentHealthWorker;
