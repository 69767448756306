
import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { images, healthWorkerIcons } from "../../../../constants/icons";



const ConsultationHealthWorker = () => {
  const data = [
    {
      name: "Jan",
      Bk: 35,
      Cl: 24,
    
    },
    {
      name: "Feb",
      Bk: 30,
      Cl: 23,
      
    },
    {
      name: "Mar",
      Bk: 20,
      Cl: 24,
    
    },
    {
      name: "Apr",
      Bk: 27,
      Cl: 30,
    
    },
    {
      name: "May",
      Bk: 18,
      Cl: 16,
      
    },
    {
      name: "Jun",
      Bk: 23,
      Cl: 23,
     
    },
    {
      name: "Jul",
      Bk: 34,
      Cl: 14,
  
    },
    {
      name: "Aug",
      Bk: 34,
      Cl: 24,
  
    },
    {
      name: "Sep",
      Bk: 34,
      Cl: 14,
  
    },
    {
      name: "Oct",
      Bk: 34,
      Cl: 24,
  
    },
    {
      name: "Nov",
      Bk: 34,
      Cl: 32,
  
    },
    {
      name: "Dec",
      Bk: 34,
      Cl: 40,
  
    },
  
  ];
  return (
    <div className=" w-[385px] h-[373px] rounded-lg bg-white p-[20px]">
      <div className="mb-6">
        <div className="flex justify-between items-center">
          <p className="text-base font-bold">Consultation</p>
          <p>
          <img src={images.verticalIcon} alt="" />
        </p>
        </div>
        <div className="flex justify-between w-[35%] text-[10px] text-textgray mt-2" >
          <p className="flex w-18 justify-between"><span className="pr-1"><img src={healthWorkerIcons.yellowIndiIcon} alt="" /></span> Ongoing</p>
          <p className="flex w-18 justify-between"><span className="pr-1"><img src={healthWorkerIcons.greenIndiIcon} alt="" /></span> <span>Completed</span></p>
        </div>
      </div>
        <div>
        <LineChart
              width={350}
          height={270}
          data={data}
          margin={{ top: 10, right:1, left: 0, bottom: 0}}
        >
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="Cl"
            stroke="#4AF06F"
            activeDot={{ r: 1}}
          />
          <Line type="monotone" dataKey="Bk" stroke="#FABC2F" />
        </LineChart>
      </div>
    </div>
  )
}

export default ConsultationHealthWorker



