import { createSlice } from "@reduxjs/toolkit";
import { registerUser, userLogin, userLogout } from "../authAction/authAction";

const accessToken = localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : null;

const initialState = {
  loading: false,
  accessToken,
  error: null,
  success: false,
  userInfo: null, // Add userInfo field to store user information
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  setCredentials: (state, { payload }) => {
    state.userInfo = payload
  },
  clearCredentials: (state) => {
    state.accessToken = null;
    state.userInfo = null;
  },
  extraReducers: {
    [userLogin.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.accessToken = payload.accessToken
      state.userInfo = payload
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },

    [registerUser.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.userInfo = payload
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [userLogout.fulfilled]: (state) => {
      state.accessToken = null;
      state.userInfo = null;
    },
    [userLogout.rejected]: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export const { setCredentials, clearCredentials  } = authSlice.actions
export default authSlice.reducer;

