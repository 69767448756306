import React from "react";
import { messaging } from "../../../../services/data/activitiesData";
import { images } from "../../../../constants/icons";

const MessagingHealthWorker = () => {
  return (
    <div className=" w-[270px] bg-white p-[20px] rounded-lg">
      <div className="flex justify-between mb-2">
        <p className="text-base font-bold">Messaging</p>
        <p>
          <img src={images.verticalIcon} alt="" />
        </p>
      </div>
      <div className="py-[20px]">
        {messaging &&
          messaging.map((item, i) => (
            <div className="flex justify-between py-1">
              <div key={i} className="w-[195px] flex justify-between">
                <div className="w-[18%]">
                  <img src={item.image} alt="" />
                </div>
                <div className="w-[80%] mb-2">
                  <p className="text-[12px]  font-semibold">{item.name}</p>
                  <p className="text-[10px] text-[#888888]">{item.mssg}</p>
                </div>
              </div>
              <div>
                <button className="bg-[#47A7FD] rounded-[5px] w-[49px] ">
                  <p className="text-[10px] text-white px-[9px] py-[5px]">
                    View
                  </p>
                </button>
              </div>
            </div>
          ))}
      </div>
      <div className="flex justify-end font-semibold text-sm  ">
        <p>See all</p>
      </div>
    </div>
  );
};

export default MessagingHealthWorker;
