import React from "react";
import { workerId } from "../../../../services/data/heathWorkerData";
import { images } from "../../../../constants/icons";

const HealthWorkerID = () => {
  return (
    <div className="bg-white rounded-lg p-[20px] mb-[15px]">
      {workerId &&
        workerId.map((item, idx) => (
          <div key={idx}>
            <div className="mb-[20px] w-[100%] flex justify-between">
              <div></div>
              <div className="text-center">
                <p className="w-fit h-fit mx-auto">
                  <img src={item.image} alt="" />
                </p>
                <p className="text-[14px] font-medium">{item.name}</p>
                <p className="text-[12px] text-textgray">{item.id}</p>
              </div>

              <p className="">
                <img src={images.verticalIcon} alt="" />
              </p>
            </div>
            <div>
              {item.personalDetails.map((data, idx) => (
                <div key={idx}>
                  <p className="text-[14px] font-medium">{item.detail}</p>
                  <div className="flex justify-between text-[12px]">
                    <div className="text-textgray ">
                      <p>{data.title}</p>
                      <p>{data.type}</p>
                      <p>{data.status}</p>
                    </div>
                    <div className="text-[#333333] font-medium">
                      <p>{data.value}</p>
                      <p>{data.profession}</p>
                      <p>{data.degree}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default HealthWorkerID;
