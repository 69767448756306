
import React, { useState, useEffect } from "react";
import axios from "axios";
import Table, {StatusPill, ActionImage, OrgActionImage} from "../../Table"; 
import axiosInstance from '../../services/axiosconfig/AxiosAuth'

function Apps() {
  const [loading, setLoading] = useState(true);
  const [superAdmindata, setData] = useState([]); // Initialize as an empty object

  useEffect(() => {
    const apiUrl = "http://stagging.mymedicalbank.com/api/v1/admin/cooperate/get-all-organisation-details";
    axiosInstance
      .get(apiUrl)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was a problem with the Axios GET request:", error);
        setLoading(false);
      });
  }, []);

  const org = superAdmindata?.data || []; // Guard against missing properties

  console.log(org)

  const columns = React.useMemo(() => [
    {
      Header: "S/n",
      accessor: (row, index) => index + 1,
    },
    {
      Header: "Name",
      accessor: "name"
    },
    {
        Header: "Phone Number",
        accessor: "mobile",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "City",
        accessor: "location_city",
      },
      {
        Header: "State",
        accessor: "location_state",
      },
      {
        Header: "Country",
        accessor: "country",
      },
      {
        Header: "Organisation Type",
        accessor: "type",
      },
    //   {
    //     Header: "Services",
    //     accessor: "services",
    //   },
 
    {
      Header: "Status",
      accessor: "status",
      Cell: StatusPill,
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: OrgActionImage,
    },
  ], []);

  return (
    <div className="min-h-screen bg-white text-black mt-6">
      <main className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-2 pt-4">
        <div className="">
          <h1 className="text-xl font-semibold font-Roboto ">Corporate Organisation</h1>
        </div>
        <div className="mt-6">
          <Table columns={columns} data={org} />
        </div>
      </main>
    </div>
  );
}

export default Apps;
