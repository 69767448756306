import React, { useState, useEffect } from "react";
import UserProfileHeader from "../../components/Users/ProfileSetup/UserProfileHeader";
import StepSidebar from '../../components/Users/HealthWorkerProfile/StepSidebar'
import ProfileForm from '../../components/Users/CorportaeOrgProfile/ProfileForm'




const CorporateOrgProfile = () => {
  return (
    <div>
    <UserProfileHeader Breadcrumb = "Registration"/>
    <div className="bg-[#F7F8FA] h-auto">
      <div className="flex flex-wrap h-auto">
        <div className="w-2/2 px-8">
        <StepSidebar
           step1Text="Input Your Business Information "
           step2Text="Input Your Services"
           />
        </div>
        <div className="w-2/2 px-12">
        <ProfileForm/>
        </div>
      </div>
  
    </div>
    </div>
  )
}

export default CorporateOrgProfile