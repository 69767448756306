
import { useAuth } from "../authProvider";
import { Navigate, Outlet } from "react-router-dom";


export const ProtectedRoute = () => {
    const { token } = useAuth();
    const accessToken = localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : null;
    // Check if the user is authenticated
    if (!accessToken) {
      return <Navigate to="/" />;
    }
    // If authenticated, render the child routes
    return <Outlet />;
  };


